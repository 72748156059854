import { Notify } from "vant";
import { getToken } from './request';

// 拿到token并返回token对象
export const getTokenToObj = () =>{
  const token = getToken();
  if(token){
    return tokenToObj(token)
  }else {
    return null
  }
}

// 格式化token字符串为对象
export const tokenToObj = (token) => {
  const tokenObj = {}
  token.split(';').forEach(item => {
    const [key, value] = item.split('=')
    tokenObj[key] = value;
  })
  return tokenObj
}

/** GLOBAL */
export const GLOBAL = {
  location: window.location,
  pagedata: window.Page_Data && JSON.parse(window.Page_Data),
};

/** 防抖 */
export const debounce = (func, wait) => {
  let timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func();
    }, wait);
  };
};

export const lenEng = (str) => {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    let c = str.charCodeAt(i);
    // 单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
      len++;
    } else {
      len = len + 2;
    }
  }
  return len;
};

export const timeFormat = (d, fmt = "yyyy-MM-dd hh:mm:ss") => {
  const date = new Date(d);
  const o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds(),
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

export const setDateTime = (timestamp, time = "") => {
  const date = new Date(timestamp);
  const [hours, minutes, seconds] = time.split(":");
  date.setHours(+hours);
  date.setMinutes(+minutes);
  date.setSeconds(+seconds);
  date.setMilliseconds(0);
  return date;
};

export const exportFile = (res) => {
  // 升级密盒下载
  // 1. 判断secDBDownload属性是否存在，如果不存在，可能的原因是JS文件引入失败。直接使用原始URL下载即可。
  // 2. 执行downloadFile进行文件下载。
  // 说明：如果URL不是文件密盒的下载地址，该方法会直接执行下载，不会发送到密盒。
  const url = res.data;
  if (window.secDBDownload) {
    window.secDBDownload.downloadFile(url, window.eraState.userInfo.login);
  } else {
    window.open(url, "_blank");
  }
};

export const copyText = (text) => {
  const input = document.createElement("input");
  document.body.appendChild(input);
  input.setAttribute("value", text);
  input.select();
  if (document.execCommand("copy")) {
    document.execCommand("copy");
    Notify({ type: "success", message: "复制成功" });
  }
  document.body.removeChild(input);
};

export const checkVideo = (url) => {
  return /\.(mp4|avi|wmv|mov|MP4|AVI|WMV|MOV)/.test(url);
};

const digitsRE = /(\d{3})(?=\d)/g;
/**
 * value  金额
 * currency 货币符号
 * decimals  保留位数
 */
export const currency = (value, decimals) => {
  value = parseFloat(value);
  if (isNaN(value)) return "-";
  decimals = decimals || 0;
  let stringified = Math.abs(value).toFixed(decimals);
  let _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
  let i = _int.length % 3;
  let head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? "," : "") : "";
  let _float = decimals ? stringified.slice(-1 - decimals) : "";
  let sign = value < 0 ? "-" : "";
  return sign + head + _int.slice(i).replace(digitsRE, "$1,") + _float;
};

/**
 * @description: 判断是不是空函数
 * @param {*} func 函数方法
 * @return {*}
 */
export const isEmptyFunction = (func) => {
  if (typeof func != 'function') {
    return false
  }
  let str = func.toString().replace(/\s+/g, '')
  str = str.match(/{.*}/g)[0]
  return str === '{}'
}

const Utils = {
  timeFormat,
  setDateTime,
  copyText,
};

Utils.install = (Vue) => {
  Vue.prototype.$GLOBAL = GLOBAL;
  Vue.prototype.$utils = Utils;
};

export default Utils;
