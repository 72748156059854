import axios from 'axios'
import { Toast } from 'vant'
import { sentryReportError } from './sentry';

axios.defaults.withCredentials = true

const elkConfig = {
  timeout: 300000,
}


const TOKEN_KEY = 'token'
const TOKEN_EXPIRE = 7 * 24 * 60 * 60 * 1000

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify({
    value: token,
    expires: Date.now()
  }))
}

export const getToken = () => {
  const tokenInfo = localStorage.getItem(TOKEN_KEY)
  const tokenExpire = TOKEN_EXPIRE
  try {
    const info = JSON.parse(tokenInfo)
    const { value, expires } = info || {}
    if (Date.now() - expires >= tokenExpire) {
      invalidateToken()
      return null
    }
    return value || null
  } catch (err) {
    console.log(err)
    return null
  }
}

export const invalidateToken = () => {
  localStorage.removeItem(TOKEN_KEY)
}

const instance = axios.create(elkConfig)

instance.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      config.headers['Content-Type'] = 'application/json'
    }
    config.withCredentials = true

    const token = getToken()

    if (token) {
      config.headers['token'] = token
    }

    return config
  },
  error => {
    Toast(`请求异常，${ error.message }`);
    sentryReportError(error, {
      scene: 'request异常',
    });
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    const config = response.config
    switch (response.status) {
      case 200:
        if (config.needRedirectLogin && response?.data?.code === 107) {
          invalidateToken()
          Toast({
            message: '登录失效，请重新登录',
          })
          return
        }
        // 如果接口401的话，页面重新刷新进入sso登陆
        if (response.data?.status === 401) {
          Toast(response.data?.msg || '登录失效，请刷新页面重试')
        }
        return response.data
      case 502:
      default:
        console.log('err')
    }
  },
  error => {
    Toast(`请求异常，${error.message}`)
    sentryReportError(error, {
      scene: 'response失败',
      url: error?.config?.url
    });
    return Promise.reject(error)
  }
)

export default instance