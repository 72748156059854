export const h5Path = [
  {
    path: "/empty",
    component: () => import("./utils/empty"),
  },
  // {
  //   path: '/skuTask',
  //   component: () => import('./skuTask/sku-task.vue'),
  // },
  {
    path: "/skuTask",
    component: () => import("./sku-task/sku-task.vue"),
  },
  {
    path: "/poiTask",
    component: () => import("./poiTask/poi-task.vue"),
  },
  {
    path: "/poiTaskDistribution",
    component: () => import("./poi-task/poi-task-distribution.vue"),
  },
  {
    path: "/openCityTaskDistribution",
    component: () => import("./openCityTask/open-city-task"),
  },
  {
    path: "/categoryTask",
    component: () => import("./category-task/category-task"),
  },
  {
    path: "/skuCountTask",
    component: () => import("./sku-baseline-task/sku-baseline-task"),
  },
  {
    path: "/skuCountReviewTask",
    component: () =>
      import("./sku-baseline-review-task/sku-baseline-review-task"),
  },
  {
    path: "/taskList",
    component: () => import("./task-list/task-list"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/crowdSourceTaskList",
    component: () => import("./crowd-source-task-list/task-list"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/collectDataList",
    component: () => import("./collect-data-list/collect-data-list"),
  },
  {
    path: "/skuSearchTask",
    component: () => import("./sku-search-task/sku-search-task.vue"),
  },
  {
    path: "/hawkEyeTask",
    component: () => import("./hawk-eye-task/hawk-eye-task.vue"),
  },
  {
    path: "/activityTask",
    component: () => import("./activity-task/activity-task.vue"),
  },
  {
    path: "/supplierLeadsTask",
    component: () => import("./supplier-leads/supplier-leads.vue"),
  },
  {
    path: "/abnormalFeedback",
    component: () => import("./abnormalFeedback/abnormalFeedback.vue"),
  },
  {
    path: "/skuApkTask",
    component: () => import("./sku-apk-task/sku-apk-task.vue")
  },
  {
    path: "/klResearchTask",
    component: () => import("./kl-research-task/kl-research-task.vue")
  },
  {
    path: "/userRegister",
    component: () => import("./user-register/user-register.vue")
  },
  {
    path: "/taskAllocate",
    component: () => import("./task-allocate/task-allocate.vue")
  }
];
