import * as Sentry from "@sentry/vue";

// 上报异常
export const sentryReportError = (err, tags = {}) => {
  try {
    Sentry && Sentry.withScope(scope => {
      Object.keys(tags).forEach(key => {
        scope.setExtra(key, tags[key])
      })
      Sentry.captureException(err)
    })
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 消息捕获
 * @param {*} message 上报信息
 * @param {*} level 上报等级 ["fatal", "error", "warning", "log", "info", "debug", "critical"]
 * @param {*} text 附加字段
 * @return {*}
 */
export const sentryReportMessage = (message='some message', level='info', text={}) => {
  try {
    Sentry && Sentry.captureMessage(message, {
      contexts: {
        text
      },
      level,
    })
  } catch(e) {
    console.log(e)
  }
}