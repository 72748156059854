import {
  Button,
  Icon,
  Toast,
  Uploader,
  Dialog,
  Notify,
  Empty,
  List,
  Image,
  ImagePreview,
  Loading,
  Cell,
  Popup,
  Picker,
  Grid,
  GridItem,
  Search,
  PullRefresh,
  ActionSheet,
  Progress,
  Popover,
  Divider,
  Cascader
} from 'vant'

export default {
  install(V) {
    V.use(Button),
    V.use(Icon),
    V.use(Toast),
    V.use(Uploader),
    V.use(Dialog),
    V.use(Notify),
    V.use(Empty),
    V.use(List),
    V.use(Image),
    V.use(ImagePreview),
    V.use(Loading),
    V.use(Cell),
    V.use(Popup),
    V.use(Picker),
    V.use(Grid),
    V.use(GridItem),
    V.use(Search),
    V.use(PullRefresh),
    V.use(ActionSheet),
    V.use(Progress),
    V.use(Popover),
    V.use(Divider),
    V.use(Cascader)
  }
}