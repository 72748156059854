import 'babel-polyfill'
import 'formdata-polyfill'
import Vue from 'vue'
import Wrap from '../Wrap'
import Utils from '../../tools/utils'
import vantConfig from '../../tools/vant-config'
import { createRouter } from '../router'
import { h5Path } from './path'
// import 'vant/lib/index.css'
import '@/assets/global.less'
import Vconsole from 'vconsole'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
const AWS_PATH = process.env.AWS_PATH || ''
const isProd = process.env.NODE_ENV === 'production'
const isTest = AWS_PATH.includes('test');

if (isTest) {
  new Vconsole();
}
Vue.config.productionTip = false
Vue.use(Utils)
Vue.use(vantConfig)

const router = createRouter({
  base: '/elk',
  pagePath: './matrixh5',
  children: [
    {
      path: '/login',
      component: () => import('./login/index')
    },
    ...h5Path,
  ],
})

Sentry.init({
  Vue,
  dsn: "https://f7e281ee6a39480fbaa3607778817bf8@kaleidoscopemonitor.danvrena.com/4",
  environment: isProd && !isTest ? 'production' : 'development',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "my-site-url.com", /^\//],
    }),
  ],
  release: '1.2.0',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


const App = new Vue({
  router,
  render: h => h(Wrap),
})
App.$mount('#app')