import Vue from 'vue'
import Router from 'vue-router'
const originalPush = Router.prototype.push, originalReplace = Router.prototype.replace;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
Router.prototype.replace = function replace(location, onComplete, onAbort) {
  if(onComplete || onAbort) {
    return originalReplace.call(this, location, onComplete, onAbort);
  }
  return originalReplace.call(this, location).catch(err => err);
}
Vue.use(Router)
export const createRouter = ({ base = '', pagePath = '', children = [] }) => {
  if (!pagePath) {
    console.error('createRouter pagePath 不可为空')
    return
  }
  const Page = resolve => require([`${pagePath}/Page`], resolve)
  return new Router({
    mode: 'history',
    base,
    routes: [
      {
        path: '/',
        component: Page,
        children,
      },
      {
        path: '*',
        component: () => import("./matrixh5/utils/empty")
      }
    ],
  })
}
